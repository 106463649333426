export type ClientType = 'subtitle_macos' | 'subtitle_win';

export type LoginNotificationMessage = {
  mail: string;
  serial: string;
  has_subscribe_history: boolean;
  access_token: string;
};

export const notifyLoginSuccess = (message: string, clientType: ClientType): void => {
  switch (clientType) {
    case 'subtitle_macos': {
      if ((window as any)?.webkit?.messageHandlers?.loginCallback?.postMessage) {
        (window as any).webkit.messageHandlers.loginCallback.postMessage(message);
      } else {
        console.error('Error: Logged in as mac OS client but messageHandlers or callback does not exist.');
      }
      break;
    }
    case 'subtitle_win': {
      if ((window as any)?.chrome?.webview?.hostObjects?.class?.loginCallback) {
        (window as any).chrome.webview.hostObjects.class.loginCallback(message);
      } else {
        console.error('Error: Logged in as Windows client but hostObjects or callback does not exist.');
      }
      break;
    }
    default:
      // Unreachable
      console.error('Error: Logged in as invalid client.');
      break;
  }
};

export const notifyStartUsing = (clientType: ClientType): void => {
  switch (clientType) {
    case 'subtitle_macos': {
      if ((window as any)?.webkit?.messageHandlers?.loginSuccessAction?.postMessage) {
        (window as any).webkit.messageHandlers.loginSuccessAction.postMessage('{"start_using_button": "clicked"}');
      } else {
        console.error('Error: Logged in as mac OS client but messageHandlers or callback does not exist.');
      }
      break;
    }
    case 'subtitle_win': {
      if ((window as any)?.chrome?.webview?.hostObjects?.class?.loginSuccessAction) {
        (window as any).chrome.webview.hostObjects.class.loginSuccessAction('{"start_using_button": "clicked"}');
      } else {
        console.error('Error: Logged in as Windows client but hostObjects or callback does not exist.');
      }
      break;
    }
    default:
      // Unreachable
      console.error('Error: Logged in as invalid client.');
      break;
  }
};

export const openNewWindow = (href: string): Window | null => {
  return window.open(href, '_blank', 'noopener norefferer');
};

import clsx from 'clsx';

const sizes = {
  sm: 'h-[32px] w-[32px] bg-[length:32px_32px] bg-center bg-no-repeat',
  md: 'h-[64px] w-[64px] bg-[length:64px_64px] bg-center bg-no-repeat',
  lg: 'h-[96px] w-[96px] bg-[length:96px_96px] bg-center bg-no-repeat',
  xl: 'h-[128px] w-[128px] bg-[length:128px_128px] bg-center bg-no-repeat',
};

const variants = {
  primary: 'bg-[url("@/assets/check_circle_green.svg")]',
};

export type CheckCircleProps = {
  size?: keyof typeof sizes;
  variant?: keyof typeof variants;
};

export const CheckCircle = ({ size = 'xl', variant = 'primary' }: CheckCircleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0,0,48,48"
    className={clsx(sizes[size], variants[variant], 'mb-4 inline overflow-hidden')}
  ></svg>
);

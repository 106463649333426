type Language = {
  value: string;
  label: string;
  langcode: string;
};

export const languages: Language[] = [
  {
    value: 'ja-jp',
    label: '日本語',
    langcode: 'ja',
  },
  {
    value: 'en-us',
    label: 'English',
    langcode: 'en',
  },
  {
    value: 'zh-cn',
    label: '中文(简体)',
    langcode: 'zh',
  },
  {
    value: 'zh-tw',
    label: '中文(繁體)',
    langcode: 'zh_TW',
  },
  {
    value: 'es-es',
    label: 'español',
    langcode: 'es',
  },
  {
    value: 'pt-pt',
    label: 'Português',
    langcode: 'pt',
  },
  {
    value: 'ru-ru',
    label: 'русский',
    langcode: 'ru',
  },
  {
    value: 'fr-fr',
    label: 'français',
    langcode: 'fr',
  },
  {
    value: 'de-de',
    label: 'Deutsch',
    langcode: 'de',
  },
  {
    value: 'ko-kr',
    label: '한국어',
    langcode: 'ko',
  },
  {
    value: 'it-it',
    label: 'Italiano',
    langcode: 'it',
  },
  {
    value: 'th-th',
    label: 'ภาษาไทย',
    langcode: 'th',
  },
  {
    value: 'ms-my',
    label: 'Melayu',
    langcode: 'ms',
  },
];

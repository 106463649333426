export type DropDownMenuItem = {
  value: string;
  label: string;
};

export type DropDownMenuProps = {
  name: string;
  id: string;
  items: DropDownMenuItem[];
  position: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const DropDownMenu = ({ name, id, items, position, onChange }: DropDownMenuProps) => {
  const options = items.map((i) => (
    <option key={i.label} value={i.value} className="block px-[2px] pt-0 pb-[1px] text-base text-black">
      {i.label}
    </option>
  ));
  return (
    <select
      name={name}
      id={id}
      onChange={(e) => onChange(e)}
      defaultValue={items[position].value}
      className="rounded-[4px] border-none bg-white p-[10px] text-base text-black sp:py-[6px] sp:px-0 sp:text-sm"
    >
      {options}
    </select>
  );
};

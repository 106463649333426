import clsx from 'clsx';

const variants = {
  primary: 'border-blue bg-blue text-white hover:border-blue-d1 hover:bg-blue-d1',
  light: 'border-black bg-white text-black hover:border-black hover:bg-black hover:text-white',
};

export type ButtonProps = {
  type?: 'button' | 'submit';
  label: string;
  variant?: keyof typeof variants;
  onClick?: () => void;
};

export const Button = ({ type = 'button', label, variant = 'primary', onClick }: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        'min-w-[180px] rounded-[50rem] border-[1px] border-solid py-[0.375rem] px-3 text-center text-base shadow-[0_8px_16px_0px_rgba(0,0,0,0.15)] transition-[color,background-color,box-shadow]',
        variants[variant]
      )}
    >
      {label}
    </button>
  );
};

import clsx from 'clsx';

const sizes = {
  md: 'text-[0.9375rem] font-normal',
  lg: 'mb-3 text-xl font-bold leading-[1.2]',
  xl: 'mb-12 text-[1.75rem] font-bold',
};

const variants = {
  primary: 'text-brack',
  alert: 'mt-3 font-semibold text-red',
};

const aligns = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

const paddings = {
  none: 'p-0',
  normal: 'py-[0.75rem] px-3 m-2',
};

export type TextBlockProps = {
  text: string;
  align?: keyof typeof aligns;
  size?: keyof typeof sizes;
  variant?: keyof typeof variants;
  padding?: keyof typeof paddings;
};

export const TextBlock = ({
  text,
  align = 'center',
  size = 'md',
  variant = 'primary',
  padding = 'none',
}: TextBlockProps) => {
  return <p className={clsx(aligns[align], sizes[size], variants[variant], paddings[padding])}>{text}</p>;
};

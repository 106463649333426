import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { availableLanguages } from '@/config';

import { DropDownMenu } from '../Elements';

type HeaderViewProps = {
  availableLanguages: typeof availableLanguages;
  selectedPosition: number;
  changeLanguage: (selectedLanguage: string) => void;
};

export const HeaderView = ({ availableLanguages, selectedPosition, changeLanguage }: HeaderViewProps) => {
  return (
    <header>
      <div className="flex min-h-[48px] items-center justify-center bg-lightgray py-3">
        <img
          className="max-w-[150px] sp:w-[40%] sp:max-w-[100px] pc:w-full"
          src={logo}
          alt="Workflow"
          decoding="async"
        />
        <div className=" absolute right-[2%]">
          <DropDownMenu
            name="language-select"
            id="language-select"
            items={availableLanguages}
            position={selectedPosition}
            onChange={(e) => changeLanguage(e.currentTarget.value)}
          ></DropDownMenu>
        </div>
      </div>
    </header>
  );
};

export const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const productCode = new URLSearchParams(location.search).get('product_code')?.toLowerCase() ?? '';
  const changeLanguage = useCallback(
    (selectedLanguage: string) => {
      navigate(`${pathname}?locale=${selectedLanguage}&product_code=${productCode}`);
      i18n.changeLanguage(selectedLanguage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, pathname, navigate]
  );

  const locale = new URLSearchParams(location.search).get('locale')?.toLowerCase();
  const selectedPosition = availableLanguages.findIndex((language) => language.value === locale);

  return (
    <HeaderView
      availableLanguages={availableLanguages}
      selectedPosition={selectedPosition}
      changeLanguage={changeLanguage}
    />
  );
};

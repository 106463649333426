import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Navigate, BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { Spinner } from '@/components/Elements/Spinner';
import { MainLayout } from '@/components/Layout';
import { availableLanguages } from '@/config';
import { lazyImport } from '@/utils/lazyImport';

const { LoginRoutes } = lazyImport(() => import('@/features/login/routes'), 'LoginRoutes');
const { LoggedinRoutes } = lazyImport(() => import('@/features/login/routes'), 'LoggedinRoutes');

const { Registered } = lazyImport(() => import('@/features/register'), 'Registered');
const { NotFound } = lazyImport(() => import('@/features/error'), 'NotFound');

// 言語判定および補正
const correctLocale = (locale: string, detected: string): string => {
  // クエリパラメータが対応言語か
  if (availableLanguages.findIndex((language) => language.value === locale) !== -1) {
    return locale;
  }

  // ブラウザ設定が対応言語か
  if (detected.includes('-')) {
    // 'en-US'形式(regionあり)
    if (availableLanguages.findIndex((language) => language.value === detected) !== -1) {
      return detected;
    }
  } else {
    // 'en'形式(regionなし)
    const found = availableLanguages.findIndex((language) => language.value.split('-').at(0) === detected);
    if (found !== -1) {
      return availableLanguages[found].value;
    }
  }

  // 上記いずれも該当しなければ英語(米国)
  return 'en-us';
};

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const locale = new URLSearchParams(location.search).get('locale')?.toLowerCase() ?? '';
  const productCode = new URLSearchParams(location.search).get('product_code')?.toLowerCase() ?? '';
  const detected = i18n.language.toLowerCase();
  const correctedLocale = correctLocale(locale, detected);

  // 補正された場合はクエリパラメータを書き換える
  if (locale !== correctedLocale) {
    i18n.changeLanguage(correctedLocale);
    return <Navigate to={`${location.pathname}?locale=${correctedLocale}&product_code=${productCode}`} replace />;
  }

  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="/login/*" element={<LoginRoutes />} />
        <Route path="/loggedin/*" element={<LoggedinRoutes />} />
        <Route path="/registered" element={<Registered />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

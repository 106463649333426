import * as React from 'react';

import { Header } from '../Header';

type ContentLayoutProps = {
  children: React.ReactNode;
};

export const ContentLayoutView = ({ children }: ContentLayoutProps) => {
  return (
    <>
      <div className="container mx-auto max-w-7xl p-4">
        <div className="justify-content flex p-2">
          <div className="max-w w-full flex-col px-3 text-center">{children}</div>
        </div>
      </div>
    </>
  );
};

export const ContentLayout = ({ children }: ContentLayoutProps) => (
  <>
    <Header />
    <ContentLayoutView>{children}</ContentLayoutView>
  </>
);
